import styled, { css } from 'styled-components';
import { Row, Col } from 'react-styled-flexboxgrid';

export const Container = styled.div`
  width: 100%;
  max-width: 500px;
  margin-bottom: 2rem;
`;

interface FormRow {
  transportMode?: boolean;
}

export const CenteredCol = styled(Col)`
  margin: auto;
`;

export const FormRow = styled(Row)<FormRow>`
  margin-top: 1rem;
  ${(props) =>
    props.transportMode
      ? css`
          position: relative;
          &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0.5rem;
            height: 100%;
            border-left-style: dotted;
            border-left-width: 5px;
            border-left-color: lightgrey;
          }
        `
      : null}
`;

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  border: 0;
  background: none;
  width: 100%;
  height: 100%;
  max-width: 2rem;
  max-height: 2rem;
  padding: 0;
  outline: none;
  cursor: pointer;
  svg {
    width: 100%;
    path {
      fill: ${({ theme }) => theme.colors.text.light};
      stroke: ${({ theme }) => theme.colors.text.light};
    }
  }
`;
