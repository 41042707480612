import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  height: 3em;
  min-height: 3em;
  width: 100%;
  padding: 1em;
  background: ${(props) => props.theme.colors.main};
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const Title = styled.span`
  cursor: default;
  display: flex;
  align-items: center;
  font-size: 1.5em;
  margin: 0;
  margin-left: 1rem;
  color: white;
  font-weight: 700;
  span {
    color: ${({ theme }) => theme.colors.secondary};
  }
  a {
    color: inherit;
    text-decoration: inherit;
  }
  sup {
    margin-left: 10px;
    font-size: 0.5em;
  }
`;

export const MobileBurger = styled.button`
  background: none;
  border: none;
  border-radius: 5px;
  color: white;
  outline: none;
  font-weight: 600;
  padding: 0.25rem;
  margin-right: 1rem;
  cursor: pointer;
  @media ${({ theme }) => theme.flexboxgrid.mediaQuery} and (min-width: ${({ theme }) =>
      theme.flexboxgrid.breakpoints.sm}em) {
    display: none;
  }
  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
  &:focus {
    background: rgba(255, 255, 255, 0.1);
  }
`;
