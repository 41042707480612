import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes, { InferProps } from 'prop-types';
import { Container, Title, MobileBurger } from './style';
import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg';

Header.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
};

export default function Header({ toggleMenu }: InferProps<typeof Header.propTypes>) {
  return (
    <Container>
      <MobileBurger onClick={toggleMenu}>
        <MenuIcon />
      </MobileBurger>
      <Title>
      <Link to="/">
        Stay or Go
        <sup>Beta</sup>
        </Link>
      </Title>
    </Container>
  );
}
