import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  margin: 0;
  background: ${({ theme }) => theme.colors.background};
  svg {
    width: 80px;
  }
`;

export const Text = styled.p`
  margin-top: 2rem;
  color: ${({ theme }) => theme.colors.text.light};
  text-align: center;
`;
