import React, { useState } from 'react';
import Header from '../Header';
import Menu from '../Menu';
import { Root, FullWidthGrid, FullHeightRow, MenuCol, Container, ContentCol } from './style';

interface PageContainerProps {
  children: React.ReactNode;
  hideMenu: boolean;
}

export default function PageContainer({ children, hideMenu }: PageContainerProps) {
  const [isOpened, setIsOpened] = useState(false);

  function toggleMenu() {
    setIsOpened(!isOpened);
  }

  return (
    <Root>
      <Header toggleMenu={toggleMenu} />
      <FullWidthGrid fluid>
        <FullHeightRow>
          <MenuCol xs={0} sm={4} md={3} lg={2} hideMenu={hideMenu} isOpened={isOpened}>
            <Menu isOpened={isOpened} setIsOpened={setIsOpened} />
          </MenuCol>
          <ContentCol xs={12} sm={hideMenu ? 12 : 8} md={hideMenu ? 12 : 9} lg={hideMenu ? 12 : 10}>
            <Container>{children}</Container>
          </ContentCol>
        </FullHeightRow>
      </FullWidthGrid>
    </Root>
  );
}
