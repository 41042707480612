import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Select } from 'formik-material-ui';
import { Field, FieldArray } from 'formik';
import { Col } from 'react-styled-flexboxgrid';
import Button from '@material-ui/core/Button';
// Components
import { DateTimePicker } from '../../../../components/DateTimePicker';
import { FormRow, IconButton, CenteredCol } from '../../../../components/Form';
import AutocompleteCity from '../../../../components/Autocomplete/City';
// Types
import { Trip, initialTripValues } from '.';
import { TravelFormValues } from '../../../../types';
// Assets
import { ReactComponent as Cross } from '../../../../assets/images/ui-cross-2.svg';

interface TripFormProps {
  values: TravelFormValues ;
  errors: any;
  touched: any;
}

function TripForm({values, errors, touched}:TripFormProps) {
  return (
    <>
      <FormRow>
        <Col xs={12}>
          <Field
            name={`origin`}
            component={AutocompleteCity}
            textFieldProps={{
              label: 'Origine',
              required: true,
            }}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col xs={12}>
          <Field
            name={`destination`}
            component={AutocompleteCity}
            textFieldProps={{
              label: 'Destination',
              required: true,
            }}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col xs={12}>
        <InputLabel htmlFor="motive">Motif de déplacement</InputLabel>
        <Field
              labelId="motive"
              component={Select}
              name={`motive`}
              inputProps={{
                id: 'motive',
              }}
              style={{width:"100%"}}
            >
            <MenuItem value="close-a-deal">Boucler une affaire</MenuItem>
            <MenuItem value="new-client">Rencontrer nouveau client</MenuItem>
            <MenuItem value="interview">Entretien d’embauche</MenuItem>
            <MenuItem value="team-building">Team building</MenuItem>
            <MenuItem value="meet-supplier">Rencontrer fournisseur</MenuItem>
            <MenuItem value="exhibition">Salons et conférences</MenuItem>
            <MenuItem value="step-point">Point d’étape</MenuItem>
            <MenuItem value="visit">Visite de chantier</MenuItem>
            <MenuItem value="crisis">Gestion de crise</MenuItem>
            </Field>
        </Col>
      </FormRow>
      {
        values.motive !== "interview" && values.motive !== "team-building" && values.motive !== "exhibition" && values.motive !== "step-point" && (
      <FormRow>
        <Col xs={12}>
          <InputLabel htmlFor="financialValue">Gain financier espéré</InputLabel>
          <Field
              labelId="financialValue"
              component={Select}
              name={`financialValue`}
              inputProps={{
                id: 'financialValue',
              }}
              style={{width:"100%"}}
            >
            <MenuItem value={0}></MenuItem>
            <MenuItem value={1}>Très faible</MenuItem>
            <MenuItem value={2}>Faible</MenuItem>
            <MenuItem value={3}>Moyen</MenuItem>
            <MenuItem value={4}>Elevé</MenuItem>
            <MenuItem value={5}>Très élevé</MenuItem>
          </Field>
        </Col>
      </FormRow>
        )
}
      <FormRow>
        <Col xs={12}>
          <InputLabel htmlFor="lastPhysicalMeeting">Date du dernier RDV physique</InputLabel>
          <Field
              labelId="lastPhysicalMeeting"
              component={Select}
              name={`lastPhysicalMeeting`}
              inputProps={{
                id: 'lastPhysicalMeeting',
              }}
              style={{width:"100%"}}
            >
            <MenuItem value="less-3-months">Dernier rdv &lt; 3 mois</MenuItem>
            <MenuItem value="less-6-months">Dernier rdv 3 à 6 mois</MenuItem>
            <MenuItem value="less-1-year">Dernier rdv 6 mois à 1 an</MenuItem>
            <MenuItem value="more-1-year">Dernier rdv &gt; 1 an</MenuItem>
            <MenuItem value="first-meeting">Première rencontre</MenuItem>
          </Field>
        </Col>
      </FormRow>

    </>
  );
}

export default TripForm;
