import React from 'react';
import { Route, Routes as ReactRoutes } from 'react-router-dom';
// Features
import About from './features/About';
import Advice from './features/Advice';
import RiskCalculation from './features/RiskCalculation/';
import LandingPage from './features/LandingPage/';
import Legal from './features/Legal';

export enum Routes {
  FAQ = '/faq',
  RISK = '/calcul/',
  TRAVEL = 'trajet',
  STAY = 'hebergement',
  HOME = '/',
  LEGAL = '/partie-legale',
}

export default function Router() {
  return (
    <ReactRoutes>
      <Route path={Routes.FAQ} element={<Advice/>}>
      </Route>
      <Route path={`${Routes.RISK}*`} element={<RiskCalculation/>}>
      </Route>
      <Route path={Routes.LEGAL} element={<Legal/>}>
      </Route>
      <Route path={Routes.HOME} element={<LandingPage/>}>
      </Route>
    </ReactRoutes>
  );
}
