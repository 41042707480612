import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
// Styles
import { Container, Label, Icon, Input, Text } from './style';

IconRadioInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default function IconRadioInput({
  onChange,
  name,
  id,
  label,
  checked,
  children,
}: InferProps<typeof IconRadioInput.propTypes>) {
  return (
    <Container>
      <Input type="radio" name={name} id={id} onChange={onChange} checked={checked} />
      <Label htmlFor={id}>
        <Icon>{children}</Icon>
        <Text>{label}</Text>
      </Label>
    </Container>
  );
}
