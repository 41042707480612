import React, { useRef, useEffect } from 'react';
import gsap, { TimelineLite } from 'gsap';
import { Link } from 'react-router-dom';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import Button from '@material-ui/core/Button';
// Assets
import { ReactComponent as LandingMobile } from '../../assets/images/landing_mobile.svg';
import { ReactComponent as LandingDesktop } from '../../assets/images/landing_desktop.svg';
import theTreepLogo from '../../assets/images/logo-tt.png';
import CEC from '../../assets/images/LogoCEC.png';
import LogoPicoty from '../../assets/images/logo_picoty.png';
import LogoGrape from '../../assets/images/logo_grape.png';
import LogoDaxium from '../../assets/images/logo_daxium.png';
import LogoPoleEmploi from '../../assets/images/logo_pole_emploi.svg.png';
import Logo2Marmottes from '../../assets/images/logo_2marmottes.png';
// Styles
import {
  Title,
  Pretitle,
  Subtitle,
  MargedRow,
  WhiteSection,
  SectionTitle,
  Powered,
  LogoImg,
  IllustrationRow,
  PartnerCol,
  PartnerImg,
  CECImg,
  PartnerRow,
} from './style';
// Utils
import { Routes } from '../../Router';
import { Box } from '@material-ui/core';

export default function LandingPage() {
  const svgDesktop = useRef<SVGSVGElement>(null);
  const svgMobile = useRef<SVGSVGElement>(null);

  return (
    <>
      <Grid>
        <Row>
          <Col xs={12}>
            <Pretitle>should I</Pretitle>
            <Title>Stay or Go?</Title>
          </Col>
        </Row>
      </Grid>

      <IllustrationRow>
        <Col xs={12} sm={false}>
          <LandingMobile ref={svgMobile} />
        </Col>
        <Col xs={false} sm={12}>
          <LandingDesktop ref={svgDesktop} />
        </Col>
      </IllustrationRow>
      <Grid>
        <Row>
          <Col xs={12}>
            <Subtitle>
            Vous êtes-vous déjà posé la question de l’essentialité de vos déplacements professionnels ?
            La réponse n’est pas évidente.
            Voici Stay or Go : un outil d’aide à la décision qui permet de peser le pour et le contre
            et d’aider à trouver la bonne réponse pour éviter le clash 😎 avec son boss.
            </Subtitle>
          </Col>
        </Row>
        <MargedRow center="xs">
          <Button component={Link} variant="contained" color="secondary" to={Routes.RISK} size="large">
          Évaluer mon prochain RDV
          </Button>
        </MargedRow>
      </Grid>
      <WhiteSection>
        <Grid>
          <Row>
            <Col xs={12} md={5}>
              <SectionTitle>C’est quoi Stay or Go?</SectionTitle>
              <p>
              Il s’agit d’un outil d’aide à la décision permettant d’estimer si un déplacement professionnel est essentiel ou pas.
              Sur la base d’informations simples comme le motif de déplacement, le coût, ou le mode de transport,
              l’outil calcule un score d’essentialité pour vous aider à juger de la pertinence de votre déplacement,
              et répondre à la question: <em>should I Stay or should I Go?</em>
              </p>
            </Col>
            <Col xs={12} md={5} mdOffset={2}>
              <SectionTitle>Pourquoi Stay or Go?</SectionTitle>
              <ul>
                <li>Parce que 79% des participants de la CEC* se demandent à chaque fois ou souvent si un déplacement professionnel vaut le coup ou pas.</li>
                <li>Parce que les déplacements pro peuvent représenter jusqu’à 90% des émissions d’une entreprise de service</li>
                <li>Parce que réduire la fréquence des déplacements est le levier le plus fort pour réduire leurs émissions de CO2</li>
              </ul>
              <p><em>(*) Convention des Entreprises pour le Climat, sur un échantillon de 43 répondants</em></p>
            </Col>
            <Col xs={12} md={5}>
              <SectionTitle>Qui sommes-nous ?</SectionTitle>
              <p>
              Nous sommes un groupe d'entreprises participantes à la <a title="Convention des Entreprises pour le Climat" href="https://cec-impact.org/">Convention des Entreprises pour le Climat</a>.
              Nous avons développé le prototype Stay or Go en nous basant sur nos propres expériences du business travel.
              Notre but est de réduire l’empreinte environnementale des voyages d’affaires. 
              </p>
            </Col>
            <Col xs={12} md={5} mdOffset={2}>
              <SectionTitle>En quoi a-t-on besoin de vous ? </SectionTitle>
              <ul>
                <li>Testez l’outil et donnez-nous votre avis pour que nous puissions nous améliorer !</li>
                <li>Partagez avec nous vos besoins spécifiques</li>
                <li>Dites-nous si vous trouvez l’outil utile !</li>
              </ul>
              <Box style={{textAlign: 'center'}}>
                <Button variant="outlined" color="secondary" href="https://share.hsforms.com/10sJxurKJTMWvJ6fOb1j4Ig2y009" target="_blank" rel="noopener noreferrer">Contactez-nous !</Button>
              </Box>
              <p>Pour en savoir plus sur notre méthodologie, <Link to="/faq">consultez la FAQ</Link></p>
            </Col>
          </Row>
          <MargedRow center="xs">
            <Col>
              <Powered>en partenariat avec</Powered>
            </Col>
          </MargedRow>
          <MargedRow center="xs">
          <Col>
                <a href="https://cec-impact.org/" target="_blank" rel="noopener noreferrer">
                  <CECImg src={CEC} alt="Convention des Entreprises pour le Climat" />
                </a>
          </Col>
          </MargedRow>
          <PartnerRow>
          <PartnerCol xs={2}>
              <PartnerImg>
                <img src={theTreepLogo} alt="The Treep" />
              </PartnerImg>
            </PartnerCol>
          <PartnerCol xs={2}>
              <PartnerImg>
                  <img src={LogoPicoty} alt="Picoty" />
              </PartnerImg>
            </PartnerCol>
          <PartnerCol xs={2}>
              <PartnerImg>
                  <img style={{maxHeight: "30px"}} src={LogoDaxium} alt="Daxium" />
              </PartnerImg>
            </PartnerCol>
          <PartnerCol xs={2}>
              <PartnerImg>
                  <img src={LogoGrape} alt="Grape" />
              </PartnerImg>
            </PartnerCol>
          <PartnerCol xs={2}>
              <PartnerImg>
                  <img src={LogoPoleEmploi} alt="Pole Emploi" />
              </PartnerImg>
            </PartnerCol>
          <PartnerCol xs={2}>
              <PartnerImg>
                  <img style={{maxHeight: "80px"}} src={Logo2Marmottes} alt="Les 2 Marmottes" />
              </PartnerImg>
            </PartnerCol>
          </PartnerRow>
          <MargedRow center="xs">
            <Col xs={10} md={5}>
              <Powered>réalisé par</Powered>
              <a href="https://www.thetreep.com">
                <LogoImg src={theTreepLogo} alt="logo the treep" />
              </a>
            </Col>
          </MargedRow>
        </Grid>
      </WhiteSection>
    </>
  );
}
