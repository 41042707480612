import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
// Local
import { myTheme, muiTheme, GlobalStyle } from './theme';
import PageContainer from './components/PageContainer';
import Loader from './components/Loader';
import Router, { Routes } from './Router';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

export default function App() {
  const [client, setClient] = useState<any>();
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setLoading(false);
  }, [setClient]);

  const hideMenu =
    location.pathname === Routes.TRAVEL ||
    location.pathname === Routes.STAY ||
    location.pathname === Routes.HOME;

  if (loading) {
    return (
      <ThemeProvider theme={myTheme}>
        <Loader />
      </ThemeProvider>
    );
  } else {
    return (
        <ThemeProvider theme={myTheme}>
          <MuiThemeProvider theme={muiTheme}>
            <GlobalStyle />
            <PageContainer hideMenu={hideMenu}>
              <ToastContainer />
              <Router />
            </PageContainer>
          </MuiThemeProvider>
        </ThemeProvider>
    );
  }
}
