import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// Style
import { MenuBackdrop, MenuContainer, MenuItem } from './style';
// Assets
import { ReactComponent as Home } from '../../assets/images/gen-home.svg';
import { ReactComponent as Estimate } from '../../assets/images/com-settings.svg';
import { ReactComponent as Information } from '../../assets/images/gen-information.svg';
import { ReactComponent as Security } from '../../assets/images/security.svg';
// Utils
import { Routes } from '../../Router';

interface MenuProps {
  isOpened: boolean;
  setIsOpened(param: boolean): void;
}

export default function Menu({ isOpened, setIsOpened }: MenuProps) {
  const location = useLocation();

  // We hide the menu when navigating to a new page
  useEffect((): void => {
    setIsOpened(false);
  }, [location, setIsOpened]);

  function toggleMenu() {
    setIsOpened(!isOpened);
  }

  return (
    <MenuBackdrop isOpened={isOpened} onClick={toggleMenu}>
      <MenuContainer
        isOpened={isOpened}
        onClick={(event: React.MouseEvent): void => event.stopPropagation()}
      >
        <ul>
          <li>
            <MenuItem to={Routes.HOME} isActive={location.pathname === Routes.HOME}>
              <Home />
              Accueil
            </MenuItem>
          </li>
          <li>
            <MenuItem to={Routes.RISK} isActive={location.pathname === Routes.RISK}>
              <Estimate />
              Évaluer un voyage
            </MenuItem>
          </li>
          <li>
            <MenuItem to={Routes.FAQ} isActive={location.pathname === Routes.FAQ}>
            <Information />
              FAQ
            </MenuItem>
          </li>
          <li>
            <MenuItem to={Routes.LEGAL} isActive={location.pathname === Routes.LEGAL}>
              <Security />
              Partie légale
            </MenuItem>
          </li>
        </ul>
      </MenuContainer>
    </MenuBackdrop>
  );
}
