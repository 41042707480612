import React from 'react';
import { Container, Text } from './style';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`;
export default function Loader() {
  return (
    <Container>
      <GlobalStyle />
      <Text>Chargement...</Text>
    </Container>
  );
}
