import styled, { css } from 'styled-components';
interface ContainerProps {
  faded: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${({ faded }) =>
    faded &&
    css`
      opacity: 0.5;
      filter: grayscale(0.5);
    `}
  margin: 0.25rem 0.25rem;
  display: flex;
  position: relative;
  height: 3rem;
  .google-visualization-tooltip { pointer-events: none }
`;

const a = 5;
const b = 1.25;
export const Needle = styled.div`
  position: absolute;
  top:-1.5rem;
  z-index:1;
  width: 0;
  height: 0;
  background: ${({ theme }) => theme.colors.main};
  ${({ theme }) => theme.shadow};
  &:before {
    content: '';
    display: block;
    position: absolute;
    box-sizing: border-box;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: ${2*b}rem ${a / 2}rem 0 ${a / 2}rem;
    border-color: transparent;
    border-top-color: ${({ theme }) => theme.colors.main};
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2)) drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.12))
      drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
    top: 100%;
  }
  span {
    position: absolute;
    left:${b}rem;
    top:3px;
    padding: 0 0.25rem;
    text-align: center;
    background: transparent;
    color: white;
    display: block;
    width: 100%;
    line-height: ${b}rem;
    box-sizing: border-box;
    font-size: 0.75rem;
    z-index: 1;
    font-weight:800;
    font-size: 0.9rem;
  }
`;

export const Gauge = styled.div`
  height: 1.75rem;
  border-radius: 5px;
  width: 100%;
  margin-top: auto;
  background: rgb(223, 230, 235);
  );
`;
