import { DefaultTheme, createGlobalStyle } from 'styled-components';
import createTheme from '@material-ui/core/styles/createTheme';
import { normalize } from 'polished';

export const GlobalStyle = createGlobalStyle`
  ${normalize()}
  html {
    height: 100%;
    color: ${({ theme }:{theme:any}) => theme.colors.text.dark};
  }
  body {
    background: ${(props: any) => props.theme.colors.background};
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #root {
    height: 100%; 
  }
`;

export const myTheme: DefaultTheme = {
  colors: {
    main: '#4A5875',
    secondary: '#51B29D',
    background: '#E7F2F9',
    lightGrey: '#EAEAEA',
    lightBlue: '#7BBEDF',
    darkGreen: '#00B59C',
    text: {
      dark: '#3D4659',
      light: 'rgba(0, 0, 0, 0.6)',
    },
  },
  shadow:
    'box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 1, // rem
    outerMargin: 1, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 46, // rem
      md: 61, // rem
      lg: 76, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 64, // em
      lg: 75, // em
    },
  },
};

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: myTheme.colors.main,
    },
    secondary: {
      main: myTheme.colors.secondary,
    },
  },
  props: {
    MuiTextField: {
      variant: 'filled',
      fullWidth: true,
    },
    MuiSelect: {
      variant: 'filled',
    },
    MuiFormControl: {
      variant: 'filled',
      fullWidth: true,
    },
  },
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: 'white',
      },
    },
    MuiButton: {
      contained: {
        borderRadius: '25px',
      },
      containedSecondary: {
        color: 'white',
      },
    },
  },
});
