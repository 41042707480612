import styled, { css } from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { IconButton } from '../components/Form';
import { PageTitle } from '../components/Typography';
import Button from '@material-ui/core/Button';

interface IndicatorProps {
  isExpanded: boolean;
}

export const RootRow = styled(Row)<IndicatorProps>`
  height: 0px;
  ${({ isExpanded }) =>
    isExpanded
      ? css`
          height: calc(100% - 1rem);
        `
      : null}
`;

export const FormCol = styled(Col)<IndicatorProps>`
  transition: all 0.3s ease-in-out;
  margin-top: 10rem;
  margin-bottom: 3rem;
  min-height: 100%;
  width: 100%;
  @media only screen and (min-width: ${({ theme }) => theme.flexboxgrid.breakpoints.lg}em) {
    margin-top: 0;
  }
  ${({ isExpanded }) =>
    isExpanded
      ? css`
          height: 0;
          opacity: 0;
        `
      : null}
`;

export const ExpandButton = styled(Button)`
  bottom: 0.25rem;
  left: 1rem;
`;


interface StayOrGoProps {
  isEnabled: boolean | null;
}

export const StayOrGo = styled.h2<StayOrGoProps>`
    font-size: 2.5rem;
    font-weight: 800;
    margin: 1rem 0rem;
    margin-top: 0rem;
    color: rgb(223, 230, 235);
    ${({ isEnabled }) =>
    isEnabled
        ? css`
            color: rgb(119, 201, 238);
            :after{
              content: '!';
            }
          `
        : null}
`;

export const IndicatorCol = styled(Col)<IndicatorProps>`
  order: -1;
  top: 3rem;
  margin-left: -0.5rem;
  margin-top: -1rem;
  margin-bottom: 2rem;
  padding: 0;
  position: fixed;
  right: 0;
  left: 0;
  /* width: 100%; */
  /* https://stackoverflow.com/questions/17806852/set-a-fixed-div-to-100-width-of-the-parent-container
    We set an empty transform value so that the fixed child gets this element's width
  */
  transform: translate(0, 0);
  ${({ isExpanded }) =>
    isExpanded
      ? css`
          height: 100%;
        `
      : null}
  @media only screen and (min-width: ${({ theme }) => theme.flexboxgrid.breakpoints.sm}em) {
    left: calc(1/3 * 100% + 3px);
  }
  @media only screen and (min-width: ${({ theme }) => theme.flexboxgrid.breakpoints.md}em) {
    left: calc(1/4 * 100% + 4px);
  }
  @media only screen and (min-width: ${({ theme }) => theme.flexboxgrid.breakpoints.lg}em) {
    position: static;
    order: 1;
    margin: 0;
    height: auto;
  }
`;

export const Indicator = styled.div<IndicatorProps>`
  display: flex;
  padding: 1rem;
  position: sticky;
  top: 4.5em;
  width: calc(100% - 1rem);
  height: 11rem;
  transition: height 0.3s ease-in-out;
  flex-direction: column;
  ${ExpandButton} {
    display: none;
  }
  ${PageTitle} {
    display: inline-flex;
    transition: all 0.3s ease-in-out;
  }
  ${IconButton} {
    display: inline-flex;
    width: 0;
    opacity: 0;
    margin-right: 0;
    transition: all 0.3s ease-in-out;
  }
  @media only screen and (max-width: ${({ theme }) => theme.flexboxgrid.breakpoints.lg}em) {
    ${({ theme }) => theme.shadow};
    background: #fff;
    ${ExpandButton} {
      display: block;
      position: absolute;
    }
    ${({ isExpanded }) =>
      isExpanded
        ? css`
            min-height: 100%;
            height: auto;
            ${PageTitle} {
              transform: translateX(1rem);
            }
            ${IconButton} {
              opacity: 1;
              width: 100%;
            }
            ${ExpandButton} {
              display: none;
            }
          `
        : null}
  }
  @media only screen and (min-width: ${({ theme }) => theme.flexboxgrid.breakpoints.lg}em) {
    box-sizing: border-box;
    height: auto;
  }
`;
