import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import { getIn } from 'formik';
import axios from 'axios';
// Types
import { City, Location } from '../../types';

interface AutocompleteProps {
  field: {
    name: string;
    value: Location | null;
  };
  type: string;
  form: {
    dirty: boolean;
    touched: boolean;
    errors: string[];
    setFieldValue(field: string, value: Location | null): void;
  };
  textFieldProps: {
    label: string;
    required: boolean;
    margin: 'none' | 'dense' | 'normal' | undefined;
  };
}

export default function Autocomplete({
  field,
  type,
  form: { dirty, touched, errors, setFieldValue },
  textFieldProps,
  ...autoCompleteProps
}: AutocompleteProps) {
  // Merge default textFieldProps with textFieldProps passed in the component
  const mergedTextFieldProps = {
    ...{
      required: false,
      fullWidth: true,
      margin: 'none',
    },
    ...textFieldProps,
  };
  const errorText = getIn(errors, field.name);
  const touchedVal = getIn(touched, field.name);
  const hasError = dirty && touchedVal && errorText !== undefined;

  const [options, setOptions] = useState([]);
  const [param, setParam] = useState('');

  useEffect(() => {
    const fetchCities = async () => {
      const query = `https://geo.api.gouv.fr/communes?nom=${param}&boost=population&limit=10&format=geojson`;
      const result = await axios(query);
      let dataResult = result.data.features || [];

      dataResult = dataResult.map((r: any) => {
        return {name:r.properties.nom,latitude:r.geometry.coordinates[1], longitude:r.geometry.coordinates[0]}
      });

      setOptions(dataResult);
    };

    fetchCities();
  }, [param]);

  return (
    <MuiAutocomplete
      options={options}
      getOptionLabel={(option) => option.name}
      onChange={(_, value) => setFieldValue(field.name, value)}
      value={field.value}
      autoSelect={true}
      noOptionsText="aucun resultat"
      renderInput={(params) => (
        <TextField
          {...params}
          error={hasError}
          onChange={(e) => setParam(e.target.value)}
          helperText={hasError ? errorText : ''}
          {...mergedTextFieldProps}
          variant="filled"
        />
      )}
      {...autoCompleteProps}
    />
  );
}
