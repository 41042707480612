import React, { useState, useEffect } from 'react';
import { Routes as ReactRoutes, Route, useNavigate } from 'react-router-dom';
// Components
import Home from './views/Home';
// Functions
import { formValuesToRiskVars } from './utils';
// Types
import { TravelFormValues, Risk, RiskVars, HotelFormValues, Estimates } from '../../types';
import { Routes } from '../../Router';
import { estimateTravel } from '../../api';

export default function RiskCaclulation() {
  const navigate = useNavigate();

  const [travelValues, setTravelValues] = useState<TravelFormValues | null>(null);
  const [estimates, setEstimates] = useState<Estimates | null>(null);
  const [hotelValues, setHotelValues] = useState<HotelFormValues | null>(null);
  const [protections, setProtections] = useState({ mask: null, gel: false });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const computeEstimates = async () => {
      if (travelValues != null) {
        setLoading(true);
        setEstimates(null);
        setEstimates(await estimateTravel(travelValues));
        setLoading(false);
      }
    };

    computeEstimates();
  }, [travelValues]);

  function handleTravelSubmit(values: TravelFormValues) {
    setTravelValues(values);
  }

  function resetEstimate() {
    setTravelValues(null);
    setHotelValues(null);
    setEstimates(null);
    setLoading(false);
  }

  return (
    <ReactRoutes>
      <Route path="/" element={
        <Home
          estimates={estimates}
          loading={loading}
          travelValues={travelValues}
          hotelValues={hotelValues}
          protections={protections}
          setProtections={setProtections}
          resetEstimate={resetEstimate}
          onSubmit={handleTravelSubmit}
          values={travelValues} 
        />} />
        </ReactRoutes>
  );
}
