import axios from 'axios';
import { Estimates, TravelFormValues } from './types';
import { toast as Notif } from 'react-toastify';

const apiURL = "https://api.stayorgo.earth"

const api = axios.create({
    baseURL: apiURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // Intercept all request response
api.interceptors.response.use(
  (response) => response,
  (error) => {
    notifyUser(error.response);
    return Promise.reject(error);
  },
);

export const estimateTravel = async (travel : TravelFormValues) => {
    const {data} = await api.post<Estimates>('/estimate/travel', {travel})
    return data
}
export const sendFeedback = async (feedback : any) => {
  const {data} = await api.post<Estimates>('/estimate/feedback', feedback)
  return data
}

// Function used to notify user that something goes wrong
const notifyUser = (error: any) => {
  const message = error.message || "Pas de résultats disponibles pour votre recherche";
    Notif.error(message, {
      autoClose: 15000,
    });
};