import React, { useState } from 'react';
import { Formik, Form, validateYupSchema, yupToFormErrors, FormikHelpers } from 'formik';
import { Col } from 'react-styled-flexboxgrid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Container, FormRow } from '../../../components/Form';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Select, TextField } from 'formik-material-ui';
import { Field, FieldArray } from 'formik';
import Button from '@material-ui/core/Button';
// Assets
import { ReactComponent as Cross } from '../../../assets/images/ui-cross-2.svg';

// Utils
import { Routes } from '../../../Router';
import { sendFeedback } from '../../../api';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal } from '@material-ui/core';

interface FeedbackValues {
    comment: string
  }

export default function FeedbackForm({ok, request, estimate, open, handleClose}: any) {
  function handleSubmit(values: FeedbackValues,{setSubmitting}:  FormikHelpers<FeedbackValues>) {
    handleClose()
    sendFeedback({feedback: {ok, ...values}, request, estimate})
    setSubmitting(false);
  }
  const initialValues : FeedbackValues= {
    comment:'',
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
       <DialogTitle>Donnez-nous votre avis !</DialogTitle>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, errors, touched, submitForm, isSubmitting  }) => (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Form>
            <DialogContent>
          <DialogContentText>
            Vous pensez que cette évaluation pourrait être plus pertinente ?
            Aidez-nous à améliorer Stay or Go en nous expliquant pourquoi.
          </DialogContentText>
        <Field
              labelId="comment"
              component={TextField}
              name={`comment`}
              inputProps={{
                id: 'comment',
              }}
              label={''}
              multiline={true}
              rows={5}
              style={{width:"100%"}}
            >
            </Field>
            </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
                  <Button variant="contained" color="secondary" disabled={isSubmitting} onClick={submitForm}>
                    Envoyer le commentaire
                  </Button>
        </DialogActions>
            </Form>
          </MuiPickersUtilsProvider>
        )}
      </Formik>
    </Dialog>
  );
}
