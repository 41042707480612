import React from 'react';
import styled from 'styled-components';

interface PageTitleProps {
  noMargin?: boolean;
}
export const PageTitle = styled.h1<PageTitleProps>`
  margin: 0;
  margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '1em')};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.dark};
  font-size: 1.5em;
`;

export const ArticleTitle = styled.h1`
  margin-bottom: 2em;
  font-size: 1em;
  color: ${({ theme }) => theme.colors.text.dark};
`;

export const ArticleParagraph = styled.p`
  margin: 0;
  margin-bottom: 2em;
  font-size: 1.1rem;
  line-height: 1.5em;
  color: ${({ theme }) => theme.colors.text.dark};
`;

export const ArticleImageCenter = styled.img`
  width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ArticleImageCenterFull = styled.img`
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ArticleImageCenterB = styled.img`
  width: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ListItem = styled.li`
  list-style: none;
  &:before {
    content: '•';
    position: absolute;
    display: inline-block;
    color: ${({ theme }) => theme.colors.text.light};
    margin-left: -1rem;
    width: 1rem;
    font-weight: bold;
  }
  p {
    margin-bottom:0.5rem;
  }
`;

interface ArticleListItemProps {
  children: React.ReactNode;
}

export const ArticleListItem = ({ children }: ArticleListItemProps) => (
  <ListItem>
    <ArticleParagraph>{children}</ArticleParagraph>
  </ListItem>
);
