import React, { useEffect, useRef, useState } from 'react';
import { gsap, TimelineLite } from 'gsap';
// Styles
import { Container, Gauge, Needle } from './style';
import { Chart } from "react-google-charts";
import { Estimate } from '../../types';

interface RiskGaugeProps {
  estimate: Estimate |null;
  risk: number | null;
  loading: boolean;
}

export default function RiskGauge({ estimate, loading }: RiskGaugeProps) {
  const needle = useRef<HTMLHeadingElement>(null);
  const [tl] = useState(new TimelineLite());
  // const [score, setScore] = useState(risk);
  let total = 0;


  const jaugeOptions = {
    animation: {
      duration: 2,
      startup: true,
    },
    chartArea: { 
      width: "100%",
      height:40,
    },
    height:40,
    isStacked: true,
    hAxis: {
      minValue: 0,
      maxValue: 100,
      textPosition: 'none',
      baselineColor: 'none',
      gridlines: {
        count: 0,
      },
    },
    vAxis: {
      textPosition: 'none',
    },
    legend: {
      position: 'none',
    },
    tooltip: {
      isHtml: true,
      trigger:'focus',
      ignoreBounds: false,
    },
    bar: { groupWidth: '100%' },
    backgroundColor: 'none',
  };

  let jaugeData : (any)[] | null= null;
  if (estimate != null) {
    const legends: (any)[] = [""];
    const data: (any)[] = [""];
    estimate.scoreDetail.byImportance.forEach((score) => {
      legends.push(score.label);
      legends.push({ role: 'annotation' });
      legends.push({ role: 'tooltip'});
      legends.push({ role: 'style' })
      data.push(score.score.int);
      if (score.score.int >= 8) {
      data.push(score.short);
      } else {
        data.push("");
      }
      data.push(`${score.label}
${score.description}`);
      data.push(score.color);
      total += score.score.int;
    })
    if (total < 100) {
      legends.push("");
      data.push(100-total);
    }
    legends.push({ role: 'style' })
    legends.push({ role: 'tooltip' });
    data.push("lightgrey")
    data.push(" ")
    jaugeData = [
      legends,
      data,
    ];
  } else {
    jaugeOptions.tooltip.trigger = 'none';
    total = 50;
    jaugeData = [
      ["","", { role: 'style' }, { role: 'tooltip' }],
      ["", 100,"lightgrey"," "],
    ];
  }
  
  

  useEffect(() => {
    gsap.set(needle.current, { left: 'calc(50% - 2.5rem)' });
    tl.to(needle.current, { duration: 0.3, left: 'calc(40% - 2.5rem)', ease: 'power2.out' });
    tl.to(needle.current, { duration: 0.6, left: 'calc(60% - 2.5rem)', ease: 'power2.inOut' });
    tl.to(needle.current, { duration: 0.3, left: 'calc(50% - 2.5rem)', ease: 'power2.in' });
    tl.pause();
    tl.repeat(-1);
  }, []);

  useEffect(() => {
    if (!loading) {
      tl.pause();
    }
    if (loading && !tl.isActive()) {
      tl.restart();
      tl.play();
    } else if (estimate != null){
  //  if (estimate !== null) {
      // setScore(risk);
      // tl.pause();
      gsap.to(needle.current, 0.5, {
        left: `calc(${total}% - 2.5rem)`,
        ease: 'power2.inOut',
      });
    }
  }, [loading, total, estimate]);

  return (
    <Container faded={estimate === null}>
      <Needle ref={needle as any}>
        <span>{loading || estimate === null ? '' : `${total}%`}</span>
      </Needle>
      {jaugeData &&
        <Chart
            chartType="BarChart"
            width="100%"
            height="100%"
            data={jaugeData}
            options={jaugeOptions}
          />
      }
    </Container>
  );
}
