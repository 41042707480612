const how = `Notre algorithme calcule un indice d’essentialité d’un voyage d’affaires sur la base de 7 critères :

1) La durée du voyage
2) Le mode de transport
3) Le coût
4) La valeur financière potentiellement générée
5) La date du dernier RDV en face to face
6) Le risque sanitaire
7) Le risque sécuritaire  

Chaque critère est évalué sur une échelle de 1 à 5 selon les données entrées par l’utilisateur et un barème préalablement défini.
Par exemple, une note de 5 est attribuée aux voyages d’une durée inférieure à 2h sur le critère “durée du voyage”, contre une note de 1 pour les voyages supérieurs à 12 heures). 
Une moyenne pondérée est ensuite calculée selon des coefficients propres à chaque motif de déplacement (ex: la valeur financière aura un poids plus important pour une visite de client et moins important pour un team building).
Le résultat est ensuite multiplié par un indice de 0 à 1 permettant de tenir compte du degré de substituabilité de l’activité concernée par de la visioconférence.`;

export default how;
