import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { transparentize } from 'polished';

interface MenuProps {
  readonly isOpened: boolean;
}

export const MenuBackdrop = styled.div<MenuProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: ${(props) => (props.isOpened ? '1' : '0')};
  pointer-events: ${(props) => (props.isOpened ? 'all' : 'none')};
  transition: opacity 0.2s ease-in-out;
  @media ${({ theme }) => theme.flexboxgrid.mediaQuery} and (min-width: ${({ theme }) =>
      theme.flexboxgrid.breakpoints.sm}em) {
    opacity: 1;
    background: none;
    position: static;
    width: auto;
    border-right: 1px solid ${({ theme }) => transparentize(0.8, theme.colors.main)};
    pointer-events: none;
  }
`;

export const MenuContainer = styled.nav<MenuProps>`
  display: flex;
  transform: translate(${(props) => (props.isOpened ? '0px' : '-100%')});
  width: 80%;
  min-width: 300px;
  height: 100%;
  left: 0;
  top: 0;
  background: white;
  transition: transform 0.2s ease-in-out;
  ul {
    padding: 0;
    flex: 1;
    margin: 1rem;
    li {
      list-style: none;
    }
  }
  @media ${({ theme }) => theme.flexboxgrid.mediaQuery} and (min-width: ${({ theme }) =>
      theme.flexboxgrid.breakpoints.sm}em) {
    background: none;
    position: static;
    width: auto;
    transform: none;
    min-width: 0;
    pointer-events: all;
    ul {
      margin-left: 0;
    }
  }
`;

interface MenuItemProps {
  isActive: boolean;
}

export const MenuItem = styled(({ isActive, ...rest }) => <Link {...rest} />)<MenuItemProps>`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  color: ${({ theme }) => theme.colors.text.dark};
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: ${(props) => (props.isActive ? 500 : 400)};
  border: ${(props) =>
    props.isActive
      ? '1px solid ' + transparentize(0.8, props.theme.colors.main)
      : '1px solid transparent'};
  margin-bottom: 0.5rem;
  &:hover {
    background: ${({ theme }) => transparentize(0.9, theme.colors.main)};
  }
  &:active {
    background: ${({ theme }) => transparentize(0.95, theme.colors.main)};
  }
  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }
`;
