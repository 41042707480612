import React, { useState, useEffect } from 'react';
import { Row, Col, Grid } from 'react-styled-flexboxgrid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// Components
import Card from '../../../components/Card';
import Gauge from '../../../components/Gauge';
import ProtectionsForm from '../../../components/ProtectionsForm';
import IndicatorResult from '../../../components/IndicatorResult';
// Styles
import {
  IndicatorCol,
  Indicator,
  ExpandButton,
  RootRow,
  FormCol,
  StayOrGo,
} from '../../../styles/Home.styled';
import { PageTitle } from '../../../components/Typography';
import { IconButton } from '../../../components/Form';
// Assets
import { ReactComponent as ArrowLeft } from '../../../assets/images/arrow-left.svg';
import trainImg from '../../../assets/images/train.svg';
import flightImg from '../../../assets/images/flight.svg';
import carImg from '../../../assets/images/car.svg';

// Utils
import { Routes } from '../../../Router';
import TravelForm from '../components/TravelForm';
import { Estimates, Estimate, TravelFormValues } from '../../../types';
import IconRadioInput from '../../../components/IconRadioButton';

interface HomePageProps {
  estimates: Estimates | null;
  loading: boolean;
  travelValues: any;
  hotelValues: any;
  protections: any;
  setProtections: any;
  resetEstimate: () => void;
  onSubmit(values: TravelFormValues): void;
  values: TravelFormValues | null;
}

export default function HomePage({
  estimates,
  loading,
  travelValues,
  hotelValues,
  resetEstimate,
  onSubmit,
  values
}: HomePageProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [estimate, setEstimate] = useState<Estimate | null>(null);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (estimates === null) {
      setEstimate(null);
    }
    if (estimates != null || loading) {
      setIsExpanded(false);
    }
    if (estimates != null && estimates.estimates.length > 0) {
      setEstimate(estimates.estimates[0]);
    }
    window.scrollTo(0, 0);
  }, [estimates, loading]);
  const isStay = estimate && !estimate.isGo;
  const isGo = estimate && estimate.isGo;
  return (
    <RootRow isExpanded={isExpanded}>
      <FormCol xs={12} lg={6} isExpanded={isExpanded}>
        <PageTitle>Voyage</PageTitle>
        <TravelForm onSubmit={onSubmit} initValues={values} />
      </FormCol>
      <IndicatorCol xs={12} lg={6} isExpanded={isExpanded}>
        <Indicator isExpanded={isExpanded}>
          {isExpanded &&
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(false);
              }}
            >
              <ArrowLeft />
            </IconButton>
}
              <Row>
                <Col xs={10}>
                  <StayOrGo isEnabled={isStay}>Stay</StayOrGo>
                </Col>
                <Col xs={2}>
                  <StayOrGo isEnabled={isGo}>Go</StayOrGo>
                </Col>
              </Row>

          <Gauge estimate={estimate} risk={estimate ? estimate.goScore.int : null} loading={loading} />
          {estimates && estimates.estimates.length > 0 && (
            <Row style={{alignItems:"center", color:"black"}}>
              <Col xs={6} style={{height:"100%"}}>
                  <strong>Avec ce mode de transport&nbsp;:</strong>
              </Col>
              {estimates.estimates.map(e => {
                const infos = transportInfos[e.transport]
                return (
                <Col xs={2}>
                  <IconRadioInput
                    name="transport"
                    id={e.transport}
                    label={infos.label}
                    onChange={() => setEstimate(e)}
                    checked={estimate ? estimate.transport == e.transport : false}
                  >
                    
                    <img style={{width:"35px"}}src={infos.logo} alt={infos.label} />
                    </IconRadioInput>
                </Col>
              )
              })}
            </Row>
          )}
          <IndicatorResult
            travelValues={travelValues}
            hotelValues={hotelValues}
            estimate={estimate}
            isExpanded={isExpanded}
          />
          {(loading || estimate) && (
            <ExpandButton onClick={() => setIsExpanded(true)} color="secondary">
              Plus de détails
            </ExpandButton>
          )}
        </Indicator>
      </IndicatorCol>
    </RootRow>
  );
}

interface TransportInfo {
  logo: any;
  label: string;
}

type TransportInfos = {
  [key: string]: TransportInfo;
}

const transportInfos : TransportInfos = {
  rail: {logo: trainImg, label: "Train"},
  flight: {logo: flightImg, label: "Avion"},
  car: {logo: carImg, label: "Voiture"},
}