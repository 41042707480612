import styled from 'styled-components';
import { Row, Col } from 'react-styled-flexboxgrid';

export const Pretitle = styled.h3`
  font-size: 1.2em;
  margin: 0;
  margin-top: 0em;
  margin-bottom: 0em;
  text-align: center;
`

export const Title = styled.h1`
  font-size: 3em;
  line-height: 0.8;
  margin: 0;
  margin-bottom: 0.2em;
  text-align: center;
  font-weight: 800;
  @media only screen and (min-width: ${({ theme }) => theme.flexboxgrid.breakpoints.sm}em) {
    font-size: 4rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.flexboxgrid.breakpoints.md}em) {
    font-size: 5.5rem;
  }
  @media only screen and (min-width: 85em) {
    font-size: 6rem;
  }
`;

export const Subtitle = styled.h2`
  font-size: 1rem;
  @media only screen and (min-width: ${({ theme }) => theme.flexboxgrid.breakpoints.sm}em) {
    font-size: 1.3rem;
  }
  font-weight: 400;
  background: ${({ theme }) => theme.colors.background};
`;

export const MargedRow = styled(Row)`
  margin: 1.5rem 0;
`;


export const WhiteSection = styled.section`
  background: white;
  width: calc(100% + 2rem);
  position: relative;
  left: -1rem;
  box-sizing: border-box;
  padding: 2rem 0;
`;

export const SectionTitle = styled.h2`
  font-size: 1.5em;
  text-align: center;
  @media only screen and (min-width: ${({ theme }) => theme.flexboxgrid.breakpoints.sm}em) {
    font-size: 2rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.flexboxgrid.breakpoints.md}em) {
    font-size: 2.5rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.flexboxgrid.breakpoints.lg}em) {
    font-size: 3rem;
  }
`;

export const Powered = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.text.light};
  text-align: center;
  @media only screen and (min-width: ${({ theme }) => theme.flexboxgrid.breakpoints.sm}em) {
    font-size: 1.2rem;
  }
`;

export const CECImg = styled.img`
  display: block;
  margin: auto;
  width:600px;
  max-width:60%;

`;

export const LogoImg = styled.img`
  display: block;
  margin: auto;
  max-width:150px;
`;

export const IllustrationRow = styled.div`
  width: calc(100% + 2rem);
  position: relative;
  top: -3rem;
  left: -1rem;
  box-sizing: border-box;
  max-height: 50vh;
  @media only screen and (min-width: 85em) {
    max-height: 50vh;
  }
  z-index: -1;
  ${Col} {
    padding: 0;
  }
`;

export const PartnerRow = styled(Row)`
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin:auto;
`;

export const PartnerCol = styled(Col)`
  display: flex;
  min-width: 150px;
`;

export const PartnerImg = styled.div`
  display: flex;
  margin: auto;
  img {
    max-height: 50px;
    margin: auto;
  }
`;
