const did_you_now = `- 79% des participants de la CEC* se demandent à chaque fois ou souvent si un déplacement professionnel vaut le coup ou pas.
- 79% des participants de la CEC* pensent qu’il est possible de signer un contrat en visio
- 90% des participants de la CEC* pensent que le train est le mode de transport le moins stressant
- 90% des participants de la CEC* sont enclin à imposer un budget carbone dans leur entreprise 
- 25% des participants de la CEC* n’ont aucun critère environnemental dans leur politique voyage, mais 90% d’entre eux sont enclins à le faire. 

(*) sur un échantillon de 43 répondants 
`;

export default did_you_now;
