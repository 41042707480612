import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width:50px;
  margin: 0;
  input {
    display: none;
  }
`;

export const Icon = styled.i`
  display: flex;
  height: 40px;
  width: 40px;
  padding: 0;
  border-radius: 4rem;
  background: white;
  box-sizing: border-box;
  margin: auto;
  margin-bottom: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  border: 2px solid transparent;
  svg {
    height: 100%;
    margin: auto;
  }
  img {
    height: 35px;
    width: 35px;
    margin: auto;
  }
`;

export const Label = styled.label`
  text-align: center;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.text.dark};
  word-break: break-word;
  ${Icon} {
    opacity: 0.5;
  }
  &:hover {
    cursor: pointer;
    ${Icon} {
      opacity: 0.8;
    }
  }
`;

export const Text = styled.span`
  display: block;
`;

export const Input = styled.input`
  &:checked ~ ${Label} {
    ${Icon} {
      opacity: 1;
    }
  }
  &:not(:checked) ~ ${Label} {
    ${Icon} svg circle {
      fill: white !important;
    }
  }
`;
