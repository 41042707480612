import styled, { css, createGlobalStyle } from 'styled-components';
import { Row } from 'react-styled-flexboxgrid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';

export const FullRow = styled(Row)`
  width: '100%';
  padding: 0;
`;

export const TitleExpension = styled.h2`
  font-size: 15px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

export const ExpPanel = styled(ExpansionPanel)`
  margin: 0;
  padding: 0;
  ${(props) =>
    props.expanded
      ? css`
          margin: 0 auto;
        `
      : null}
`;

export const ArticleStyles = createGlobalStyle`
    .responsive-images {
        display: flex;
        flex-direction: column;
        justify-content: center;
        img {
            margin: 1em auto;
            width: 80%;
            max-width: 300px;
        }
        @media only screen and (min-width: ${({ theme }) => theme.flexboxgrid.breakpoints.md}em) {
            flex-direction: row;
        }
    }
`;
