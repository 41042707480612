import { Col } from 'react-styled-flexboxgrid';
import styled, { css } from 'styled-components';

interface IndicatorProps {
  isExpanded: boolean;
}

export const Container = styled.div<IndicatorProps>`
  display: none;
  flex-direction: column;
  ${({ isExpanded }) =>
    isExpanded &&
    css`
      display: flex;
    `}
  @media only screen and (min-width: ${({ theme }) => theme.flexboxgrid.breakpoints.lg}em) {
    display: flex;
  }
`;

export const Points = styled.div`
  color: black;
  ul {
    margin-bottom: 0px;
  }
`;

export const PointsTitle = styled.h3`
  display: inline-flex;
  margin-bottom: 0px;
  align-items: center;
  color: black;
`;

export const PositivePoint = styled.h3`
  color: #01dcbe;
  display: inline-flex;
  align-items: center;
`;

export const NegativePoint = styled.h3`
  color: #f18888;
  display: inline-flex;
  align-items: center;
`;

export const Disclaimer = styled.p`
  color: ${({ theme }) => theme.colors.text.light};
  font-size: 0.9rem;
  margin-top: 0.5em;
`;
