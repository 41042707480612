import React, { useState } from 'react';
import { Formik, Form, validateYupSchema, yupToFormErrors, FormikHelpers } from 'formik';
import { Col } from 'react-styled-flexboxgrid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Container, FormRow } from '../../../../components/Form';
import Button from '@material-ui/core/Button';
import { TravelFormValues } from '../../../../types';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
// Components
import TripForm from './TripForm';
// Utils
import { Routes } from '../../../../Router';

const dateFns = new DateFnsUtils();

interface TravelFormProps {
  onSubmit(values: TravelFormValues): void;
  initValues: TravelFormValues | null;
}

export enum Trip {
  OUTWARD = 'outward',
  RETURN = 'return',
}

export const initialTripValues: TravelFormValues = {
  origin: null,
  destination: null,
  motive: '',
  financialValue: 0,
  lastPhysicalMeeting: '',
};

// For our yup schema
const emptyTripValues = {
    location: null,
    datetime: null,
    motive: '',
    financialValue: 0,
    lastPhysicalMeeting: '',
};

const REQUIRED_TEXT = 'Veuillez remplir ce champ.';

const tripValidationSchema = yup.object().shape({
  origin: yup.object().shape({
    location: yup
      .object()
      .shape({
        properties: yup
          .object()
          .shape({
            nom: yup.string().required(REQUIRED_TEXT),
          })
          .required(REQUIRED_TEXT),
      })
      .required(REQUIRED_TEXT),
    datetime: yup.date().nullable(),
  }),
  destination: yup.object().shape({
    location: yup
      .object()
      .shape({
        properties: yup
          .object()
          .shape({
            nom: yup.string().required(REQUIRED_TEXT),
          })
          .required(REQUIRED_TEXT),
      })
      .required(REQUIRED_TEXT),
    datetime: yup.date().nullable(),
  }),
  motive: yup.string().required(REQUIRED_TEXT),
  lastPhysicalMeeting: yup.string().required(REQUIRED_TEXT),
});

export default function TravelForm({ onSubmit, initValues }: TravelFormProps) {
  const initialValues: TravelFormValues = initValues
    ? 
      initValues
    : initialTripValues;

  function handleSubmit(values: TravelFormValues,{setSubmitting}:  FormikHelpers<TravelFormValues>) {
    onSubmit(values);
    setSubmitting(false);
  }

  function validation(values: TravelFormValues) {
    // @TODO
    // try {
    //   validateYupSchema<TravelFormValues>(values, tripValidationSchema, true, { formValues: values });
    // } catch (err) {
    //   return yupToFormErrors(err);
    // }
    return {};
  }

  return (
    <Container>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validation}>
        {({ values, errors, touched, submitForm, isSubmitting  }) => (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Form>
              <TripForm
                values={values}
                errors={errors}
                touched={touched}
              />
              <FormRow end="xs">
                <Col>
                  <Button type="reset" color="secondary">
                    Réinitialiser
                  </Button>
                </Col>
                <Col>
                  <Button variant="contained" color="secondary" disabled={isSubmitting} onClick={submitForm}>
                    Valider
                  </Button>
                </Col>
              </FormRow>
            </Form>
          </MuiPickersUtilsProvider>
        )}
      </Formik>
    </Container>
  );
}
