import React from 'react';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import { Col } from 'react-styled-flexboxgrid';
import ReactMarkdown from 'react-markdown';
// Articles
import what  from './articles/what';
import why from './articles/why';
import how from './articles/how';
import company from './articles/company';
import applications from './articles/applications';
import why_company from './articles/why_company';
import who from './articles/who';
import needs from './articles/needs';
import did_you_know from './articles/did_you_know';

// Assets
import { ReactComponent as ExpandMoreIcon } from '../../assets/images/add_circle-black-24dp.svg';
import { ReactComponent as ExpandLessIcon } from '../../assets/images/remove_circle-black-24dp.svg';
// Styles
import {
  ArticleTitle,
  ArticleParagraph,
  ArticleListItem,
  ArticleImageCenterB,
} from './../../components/Typography';
import { FullRow, TitleExpension, ExpPanel, ArticleStyles } from './style';
import { Box, Button } from '@material-ui/core';

export enum Articles {
  WHAT = 'C’est quoi Stay or Go?',
  WHY = 'Pourquoi Stay or Go?',
  HOW = 'Comment ça marche ?',
  COMPANY = 'Puis-je adapter l’outil Stay or Go à mon entreprise et mon degré d’ambition ?',
  APPLICATIONS = 'Quelles applications possibles de Stay or Go ?',
  WHY_COMPANY = 'Que gagne-t-on à utiliser Stay or Go en entreprise ?',
  WHO = 'Qui sommes-nous ?',
  NEEDS = 'En quoi a-t-on besoin de vous ?',
  DID_YOU_KNOW = 'Le saviez-vous ?',
}

function LinkRenderer(props: any) {
  return (
    <Box style={{textAlign: 'center'}}>
      <Button variant="outlined" color="secondary" target="_blank" rel="noopener noreferrer" href={props.href}>{props.children}</Button>
    </Box>
  );
}

export default function Advice() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<any>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const displayArticle = (key: string, title: string, content: string) => (
    <ExpPanel expanded={expanded === key} onChange={handleChange(key)}>
          <ExpansionPanelSummary
            expandIcon={
              expanded === key ? (
                <ExpandLessIcon style={{ fill: '#01DCBE' }} />
              ) : (
                <ExpandMoreIcon style={{ fill: '#01DCBE' }} />
              )
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <TitleExpension>{title}</TitleExpension>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              <ReactMarkdown
                source={content}
                renderers={{
                  heading: ArticleTitle,
                  paragraph: ArticleParagraph,
                  listItem: ArticleListItem,
                  image: ArticleImageCenterB,
                  link: LinkRenderer,
                }}
              />
            </Typography>
          </ExpansionPanelDetails>
        </ExpPanel>
  )

  return (
    <FullRow>
      <ArticleStyles />
      <Col xs={12} lg={7}>
      {displayArticle('what', Articles.WHAT, what)}
      {displayArticle('why', Articles.WHY,why)}
      {displayArticle('how', Articles.HOW,how)}
      {displayArticle('company', Articles.COMPANY,company)}
      {displayArticle('applications', Articles.APPLICATIONS,applications)}
      {displayArticle('why_company', Articles.WHY_COMPANY,why_company)}
      {displayArticle('who', Articles.WHO,who)}
      {displayArticle('needs', Articles.NEEDS,needs)}
      {displayArticle('did_you_know', Articles.DID_YOU_KNOW,did_you_know)}
      </Col>
    </FullRow>
  );
}
