import React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
// Assets
import { ReactComponent as ExpandMoreIcon } from '../../assets/images/add_circle-black-24dp.svg';
import { ReactComponent as ExpandLessIcon } from '../../assets/images/remove_circle-black-24dp.svg';

export default function CGU() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<any>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Row>
        <Col xs={12} lg={7}>
          <ExpansionPanel expanded={expanded === 'cgu'} onChange={handleChange('cgu')}>
            <ExpansionPanelSummary
              expandIcon={
                expanded === 'cgu' ? (
                  <ExpandLessIcon style={{ fill: '#01DCBE' }} />
                ) : (
                  <ExpandMoreIcon style={{ fill: '#01DCBE' }} />
                )
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <strong>Conditions générales d'utilisation</strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div>
                <h3>PREAMBULE</h3>

                <p>
                  Le site Internet à l’adresse https://stayorgo.earth (ci-après le « Site » ou «
                  Stay or Go » ) est géré par la société The Treep SAS (ci-après «The Treep »),
                  Société par actions simplifiée au capital de 68 030€ euros, immatriculée au
                  Registre du commerce et des sociétés de Nanterre sous le numéro B 820 330 504,
                  dont le siège social est 1 Place Paul Verlaine, 92100 Boulogne Billancourt, le
                  numéro de téléphone le +33 9 72 66 35 70 et l'adresse mail contact@thetreep.com.
                </p>
                <p>
                  The Treep est immatriculée au Registre des Opérateurs de Voyages et de Séjours
                  sous le numéro IM092170015. Garantie Financière : APST, 15 Avenue Carnot, 75017
                  Paris. RCP : HISCOX, 12 quai des Queyries 33100 Bordeaux.
                </p>
                <p>
                  L’Utilisation des services proposés par le Site vaut acceptation sans réserves des
                  présentes Conditions Générales d’Utilisation.
                </p>
                <h3>ARTICLE 1 – OBJET DES SERVICES PROPOSES</h3>

                
                <h3>ARTICLE 2 – DEFINITIONS</h3>

                <p>
                  CGU : Désigne les Conditions Générales d’Utilisation, s'appliquant, sans
                  restriction ni réserve, à l'ensemble des Services proposés par le Site ;
                </p>
                <p>Services : Désigne les services accessibles aux Utilisateurs du Site ;</p>
                <p>
                  Site : Désigne le site internet accessible à l'adresse suivante :
                  https://stayorgo.earth
                </p>
                <p>
                  Utilisateur : Désigne un Internaute utilisant les Services proposés sur le Site,
                  qu’il soit ou non titulaire d’un Compte Utilisateur ;
                </p>
                <p>
                  Compte Utilisateur : Désigne le compte sur permettant à l'Utilisateur de disposer
                  de certains services dont ne bénéficient pas les Utilisateurs n’ayant pas ouvert
                  de compte.
                </p>
                <h3>ARTICLE 3 – CONDITIONS d’ACCES AUX SERVICES</h3>

                <p>3.1 Ouverture d’un Compte Utilisateur</p>
                <p>L’accès au Site est ouvert à toute personne.</p>
                <p>
                  L’accès à certains Services (enregistrement de sa recherche par un Utilisateur et
                  actualisation des résultats de sa recherche la veille de son départ) nécessite
                  cependant la création d’un Compte Utilisateur.
                </p>
                <p>
                  Cette possibilité est offerte à toute personne âgée de 18 ans au moins ayant
                  rempli le formulaire d’inscription permettant l’ouverture d’un Compte Utilisateur.
                </p>
                <p>3.2 Choix d’un identifiant et d’un mot de passe.</p>
                <p>
                  Lors de leur inscription, les Utilisateurs seront tenus de fournir une adresse
                  mail valide et un mot de passe.
                </p>
                <p>
                  Le mot de passe est confidentiel et les Utilisateurs doivent s’abstenir de le
                  communiquer à quiconque.
                </p>
                <p>
                  En cas de perte du mot de passe ou de divulgation de celui-ci, les Utilisateurs
                  sont tenus de faire une demande de modification de leur mot de passe en suivant la
                  procédure indiquée sur le Site.
                </p>
                <h3>ARTICLE 4 – CONDITIONS FINANCIERES</h3>

                <p>4.1 Frais de connexion</p>
                <p>
                  Les équipements (ordinateur, logiciels, moyens de télécommunications, etc.)
                  permettant l'accès aux Services sont à la charge exclusive de l’Utilisateur, de
                  même que les frais de communications relatifs à cette utilisation.
                </p>
                <p>
                  4.2 Conditions financières relatives à l’Utilisation du Site et Services proposés
                </p>
                <p>
                  L’accès au Site, aux Services proposés, y compris aux Services nécessitant
                  l’ouverture d’un Compte Utilisateur, est totalement gratuit.
                </p>
                <h3>ARTICLE 5 – RESILIATION DU COMPTE OU DE L’ABONNEMENT D’UN UTILISATEUR</h3>

                <p>5.1 Résiliation de compte à la demande d’un Utilisateur</p>
                <p>
                  Tout Utilisateur peut résilier son compte à tout moment, sans motif, notamment via
                  la rubrique "Mon Compte" du Site.
                </p>
                <p>
                  La demande de résiliation d’un compte peut aussi être demandée par e-mail à
                  l’adresse suivante : contact@thetreep.com
                </p>
                <p>5.2 Effets de la résiliation de compte</p>
                <p>
                  La résiliation d’un Compte Utilisateur entraîne la suppression de toutes les
                  données personnelles le concernant.
                </p>
                <p>Les données sont complétement effacées au bout d’une durée de 30 jours.</p>
                <h3>ARTICLE 6 – RESPONSABILITE </h3>

                <p>6.1 Accès aux Services proposés</p>
                <p>
                  Stay or Go s'efforce de maintenir accessible le Site 7 jours sur 7 et 24 heures
                  sur 24, mais n’est tenue à aucune obligation d'y parvenir.
                </p>
                <p>
                  Stay or Go pourra cependant interrompre, pour un temps déterminé ou non, tout ou
                  partie de l'accès au Site et/ou aux Services, notamment, pour des raisons de
                  maintenance et de mise à niveau, ou pour toutes autres raisons, notamment
                  techniques et/ou cas de force majeure, sans aucune obligation d'information
                  préalable des Partenaires ni sans qu’une telle interruption n’ouvre droit à aucune
                  indemnité ou un quelconque remboursement.
                </p>
                <p>En outre, les Utilisateurs reconnaissent et acceptent expressément que :</p>
                <p>
                  − Stay or Go ne garantit pas que le Site ou les Services proposés soit exempts de
                  virus informatiques ni d'autres anomalies techniques indépendantes de sa volonté.
                </p>
                <p>
                  − Les transmissions de données sur le réseau Internet ne bénéficiant que d’une
                  fiabilité technique relative, Stay or Go ne saurait être responsable d’une part,
                  des interruptions, lenteurs et inaccessibilités au réseau Internet et, par voie de
                  conséquence au Site et d’autre part, du fait que le réseau Internet puisse être
                  saturé à certains moments de la journée rendant l’accès au Site très difficile
                  voire impossible.
                </p>
                <p>
                  − Stay or Go n'offre aucune garantie quant au fonctionnement ininterrompu et/ou la
                  continuité du Site et des Services en cas de force majeure ou cas fortuits tels
                  que définis par la réglementation et la jurisprudence en vigueur. Sont considérés
                  notamment comme cas de force majeure : les grèves totales ou partielles, internes
                  ou externes à l’entreprise, lock-out, épidémies, blocage des moyens de transports
                  ou d’approvisionnement pour quelque raison que ce soit, tremblement de terre,
                  incendie, tempête, inondation, dégâts des eaux, les catastrophes naturelles, les
                  la foudre, les attentats, les cas de rupture et de blocage des réseaux de
                  télécommunication, les dommages provoqués par des virus pour lesquels les moyens
                  de sécurité existant sur le marché ne permettent pas leur éradication, ainsi que
                  toute obligation légale ou réglementaire ou d'ordre public imposée par les
                  autorités compétentes et qui auraient pour effet de modifier substantiellement
                  l’application des présentes Conditions Générales d’Utilisation.
                </p>
                
                <h3>ARTICLE 8 – DONNEES PERSONNELLES ET VIE PRIVEE</h3>

                <p>
                  Stay or Go est susceptible de collecter des données à caractère personnel dans le
                  cadre de la consultation du Site et des commandes qui y sont passées, dans le
                  respect de la loi du 6 janvier 1978 relative à l'informatique, aux fichiers et aux
                  libertés et en conformité avec le e règlement nᵒ 2016/679, dit règlement général
                  sur la protection des données (RGPD).
                </p>
                <p>
                  Les informations relatives à la politique des traitements des données personnelles
                  Stay or Go sont accessibles dans notre document relatif à la politique de
                  confidentialité des données à caractère personnel.
                </p>
                <h3>ARTICLE 9 – PROPRIETE INTELLECTUELLE</h3>

                <p>
                  Les marques, les logos, les graphismes, les photographies, les animations, les
                  vidéos et les textes contenus sur le Site sont la propriété de Stay or Go ou de
                  ses partenaires et ne peuvent être reproduits, utilisés ou représentés sans
                  l'autorisation expresse d’Stay or Go ou de ses partenaires, sous peine de
                  poursuites judiciaires.
                </p>
                <p>
                  Il est strictement interdit aux Utilisateurs de modifier, copier, reproduire,
                  télécharger, diffuser, transmettre, exploiter commercialement et/ou distribuer de
                  quelque façon que ce soit les Services, les pages du Site et des éléments
                  composant les Services accessibles sur le Site.
                </p>
                <h3>ARTICLE 10 – DROIT APPLICABLE</h3>

                <p>
                  Les présentes Conditions Générales d’Utilisation sont soumises au droit français.
                  Tout litige relatif à leur interprétation et/ou à leur exécution relève des
                  Tribunaux français.
                </p>
                <p>
                  Les Utilisateurs sont également informés qu’ils peuvent, en cas de litige avec
                  Stay or Go, avoir recours à une procédure de médiation conventionnelle ou à tout
                  autre mode alternatif de règlement des litiges tels que définis en droit français.
                </p>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel expanded={expanded === 'pc'} onChange={handleChange('pc')}>
            <ExpansionPanelSummary
              expandIcon={
                expanded === 'pc' ? (
                  <ExpandLessIcon style={{ fill: '#01DCBE' }} />
                ) : (
                  <ExpandMoreIcon style={{ fill: '#01DCBE' }} />
                )
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <strong>Politique de Confidentialité</strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div>
                <p>
                  La présente Politique de Confidentialité est relative aux données personnelles
                  collectées par la société The Treep, dans le cadre des services proposés sur le
                  site internet accessible à l’adresse https://stayorgo.earth, (Ci-après dénommé «
                  Le Site ») dont elle assure la gestion.
                </p>
                <p>Le présent document a pour objet d’informer les Utilisateurs du Site sur :</p>
                <p>- La nature des données collectées ;</p>
                <p>- Les finalités de la collecte ;</p>
                <p>
                  - L’utilisation qui est faite de ces données, et les éventuels transferts auprès
                  de tiers ;
                </p>
                <p>- Les conditions de conservation des données ;</p>
                <p>- Les conditions d’exercice de leurs droits ;</p>
                <h3>Préambule</h3>

                <p>
                  Le Règlement (UE) 2016/679 du Parlement Européen et du Conseil du 27 avril 2016
                  relatif à la protection des personnes physiques à l’égard du traitement des
                  données à caractère personnel et à la libre circulation de ces données, et
                  abrogeant la directive 95/46/CE (« RGPD ») est applicable aux traitements des
                  Données Personnelles lorsque le traitement est effectué dans le territoire de
                  l’Espace Économique Européen (« EEE »).
                </p>
                <p>Pourquoi collectons-nous les données des Utilisateurs ?</p>
                <p>
                  L’utilisation des services proposées sur le Site n’implique pas nécessairement une
                  collecte des données personnelles des Utilisateurs, le Site pouvant être utilisée
                  de façon anonyme par tout Utilisateur.
                </p>
                <p>
                  Néanmoins, l’enregistrement par un Utilisateur de la requête qu’il a formulée,
                  ainsi le cas échéant que l’actualisation des résultats de sa recherche implique la
                  création d’un Compte Utilisateur.
                </p>
                <p>
                  Ce processus nécessite que l’Utilisateur enregistre auprès du Site une adresse
                  E-mail et un mot de passe.
                </p>
                <h3>Quelles sont les données personnelles des Utilisateurs collectées ?</h3>

                <p>
                  Le Site n’enregistre, pour chaque Utilisateur qu’une adresse E-mail et un mot de
                  passe, à l’exclusion, de leur nom, prénom, adresse, numéro de téléphone, adresse
                  IP, données de connexions et données de navigation, historiques re recherche,
                  cookies.
                </p>
                <p>
                  La collecte de ces données intervient uniquement lorsqu’un Utilisateur ouvre un
                  Compte sur le Site.
                </p>
                <p>
                  Le Site n’utilise pas non plus d’outil de géolocalisation lui permettant de
                  localiser physiquement les Utilisateurs, sauf éventuellement, lors de l’ouverture
                  d’un Compte Utilisateur sur le Site.
                </p>
                <h3>Les données sont-elles partagées ?</h3>

                <p>
                  Le Site ne communique ni ne commercialise aucunement les données des Utilisateurs
                  à d’autres Utilisateurs ou à des tiers. Les données collectées sont uniquement
                  utilisées afin d’établir des statistiques utilisées par l’algorithme du Site et
                  afin que les Utilisateurs puissent recevoir une actualisation de leurs recherches.
                </p>
                <h3>
                  Pendant combien de temps les données des Utilisateurs sont-elles conservées ?
                </h3>

                <p>
                  Les données des Utilisateurs sont conservées par défaut pendant une durée de 2 ans
                  à compter de leur collecte, ou bien jusqu’à ce que nous recevions une demande de
                  destruction.
                </p>
                <h3>Où les données sont-elles stockées ?</h3>

                <p>
                  Les données des Utilisateurs sont stockées au sein de l’Espace Économique
                  Européen, sur un serveur dédié situé dans nos locaux et auquel seul notre
                  personnel à accès, et protégé par un système de mot de passe.
                </p>
                <h3>Comment exercer vos droits, et nous contacter ?</h3>

                <p>
                  Les Utilisateurs bénéficient d’un droit d’accès, droit à l’information, droit de
                  rectification, droit à la limitation du traitement, droit à la portabilité des
                  données, droit d’opposition et droit d’effacement de leurs données.
                </p>
                <p>
                  Pour exercer ces droits ou pour toute interrogation relative à la Politique de
                  Confidentialité du Site, les Utilisateurs peuvent nous contacter par courriel à
                  l’adresse suivante : contact@thetreep.com, ou bien nous écrire à l’adresse
                  suivante : The Treep, 1 Place Paul Verlaine, 92100 Boulogne Billancourt.
                </p>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel expanded={expanded === 'mentions'} onChange={handleChange('mentions')}>
            <ExpansionPanelSummary
              expandIcon={
                expanded === 'mentions' ? (
                  <ExpandLessIcon style={{ fill: '#01DCBE' }} />
                ) : (
                  <ExpandMoreIcon style={{ fill: '#01DCBE' }} />
                )
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <strong>Mentions légales</strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div>
                <p>the Treep SAS au capital de 68 030€</p>
                <p>1 Place Paul Verlaine 92100 Boulogne-Billancourt</p>
                <p>contact@thetreep.com +339 72 66 35 70</p>
                <p>820 330 504 R.C.S. Nanterre</p>
                <p>Agence de voyages immatriculée chez Atout France sous le numéro IM092170015</p>
                <p>Atout France 79/81 rue de Clichy, 75009 PARIS</p>
                <p>TVA intracommunautaire FR62 820330504</p>
                <p>Déclaration CNIL n° 2148175</p>
                <p>Directeur de la publication : Laurent La Rocca</p>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Col>
      </Row>
    </>
  );
}
