import styled from 'styled-components';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ContentCol = styled(Col)``;

export const Container = styled.main`
  padding-top: 1em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const FullWidthGrid = styled(Grid)`
  margin: 0;
  margin-top: 4.5rem;
  flex: 1;
`;

export const FullHeightRow = styled(Row)`
  height: 100%;
`;

interface MenuColProps {
  hideMenu: boolean;
  isOpened: boolean;
}

export const MenuCol = styled(Col)<MenuColProps>`
  padding: 0;
  z-index: 2;
  pointer-events: ${({ isOpened }) => (isOpened ? 'all' : 'none')};
  @media only screen and (min-width: ${({ theme }) => theme.flexboxgrid.breakpoints.sm}rem) {
    display: ${({ hideMenu }) => (hideMenu ? 'none' : 'block')};
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    z-index: 0;
  }
`;
