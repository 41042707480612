import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
// Components
import { Row, Col } from 'react-styled-flexboxgrid';
// Assets
import { ReactComponent as ExpertIcon } from '../../assets/images/expert.svg';
import { ReactComponent as PositiveIcon } from '../../assets/images/add_circle-black-24dp.svg';
import { ReactComponent as NegativeIcon } from '../../assets/images/remove_circle-black-24dp.svg';
// Styles
import { Container, Points, PointsTitle } from './style';
// Types
import { TravelFormValues, HotelFormValues, Estimate } from '../../types';
// Util
import { Routes } from '../../Router';
import FeedbackForm from '../../features/RiskCalculation/components/FeedbackModal';
import { Button, IconButton } from '@material-ui/core';
import { sendFeedback } from '../../api';
import { Alert } from '@material-ui/lab';

interface IndicatorResultProps {
  estimate: Estimate | null;
  isExpanded: boolean;
  travelValues: TravelFormValues | null;
  hotelValues: HotelFormValues | null;
}

export default function IndicatorResult({
  estimate,
  isExpanded,
  travelValues,
}: IndicatorResultProps) {
  const [feedbackModalOpen, setFeedbackModalOpen] = React.useState(false);
  const [ok, setOK] = React.useState(true);
  const [feedbackSent, setFeedbackSent] = React.useState(false);

  useEffect(() => {
    setFeedbackSent(false);
    setFeedbackModalOpen(false);
  }, [estimate]);

  return (
    <Container isExpanded={isExpanded}>
      {estimate && (
        <>
          <Row>
            <Col xs={12} style={{color:'#3D4659'}}>
              {estimate.summary}
            </Col>
          </Row>
          {estimate.pluses?.length > 0 && (
            <Points>
              <PointsTitle>
                Raisons pour y aller
              </PointsTitle>
              <ul>
                {estimate.pluses?.map((plus, i) => (
                  <li key={i}>{plus}</li>
                ))}
              </ul>
            </Points>
          )}
          {estimate.minuses?.length > 0 && (
            <Points>
              <PointsTitle>
                Raisons pour rester
              </PointsTitle>
              <ul>
                {estimate.minuses.map((minus, i) => (
                  <li key={i}>{minus}</li>
                ))}
              </ul>
            </Points>
          )}
          <Row style={{alignItems: "center", marginTop:"20px"}}>
            <Col xs={6} style={{height: "100%"}}><PointsTitle style={{margin:"0px"}}>D'accord avec ce résultat ?</PointsTitle></Col>
            <Col xs={2}>
              <Button variant="outlined" color="secondary" disabled={feedbackSent} size="small" onClick={() => {
                setOK(true);
                setFeedbackModalOpen(true)
              }}>
            OUI
              </Button>
            </Col>
            <Col xs={2}>
              <Button variant="outlined" color="secondary" disabled={feedbackSent} size="small" onClick={() => {
                setOK(false);
                setFeedbackModalOpen(true);
              }}>
              NON
              </Button></Col>
          </Row>
          <FeedbackForm request={travelValues} estimate={estimate} ok={ok} open={feedbackModalOpen} handleClose={() => {
            setFeedbackModalOpen(false);
            setFeedbackSent(true);
          }}/>
        </>
      )}
    </Container>
  );
}